@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --mobile-nav-height: 64px;
}

* {
  font-variant-emoji: text;
  text-rendering: geometricPrecision;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
  .safe-area-bottom {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
    --mobile-nav-height: 56px;
    --header-height: 96px;
  }
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;

  }
  /* Add specific styling for the brand name if needed */
  .brand-name {
    font-family: "Macondo Swash Caps", cursive;
    font-size: 1.5rem;
    font-weight: 400;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground font-body;
    font-size: 1em;
  }
  h1 {
    @apply font-heading;
  }
  h2,
  h3 {
    @apply font-subheading;
  }
  p,
  span,
  div,
  body,
  strong,
  em {
    @apply font-body;
  }

  input,
  select,
  textarea {
    @apply text-base py-3 px-4;
  }
  button {
    @apply text-base py-3 px-6;
  }
}

/* Adjust shadcn component sizes */
@layer components {
  .card {
    @apply p-6;
  }
  .input {
    @apply text-base py-3 px-4;
  }
  .btn,
  .button {
    @apply text-base py-3 px-6;
  }
  .label {
    @apply text-base mb-2;
  }
  .select {
    @apply text-base py-3 pl-4 pr-10;
  }
  .textarea {
    @apply text-base p-4;
  }
  .checkbox,
  .radio {
    @apply w-5 h-5;
  }
}

/* Additional utility classes for flexible sizing */
@layer utilities {
  .text-larger {
    font-size: 1.1em;
  }
  .p-larger {
    padding: 1.25em;
  }
  .m-larger {
    margin: 1.25em;
  }
}

/* Add these styles for the background component */
.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #3e1e68;
  overflow: hidden;
  z-index: -1;
}

.background span {
  width: 3vmin;
  height: 3vmin;
  border-radius: 3vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 30;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.background span:nth-child(0) {
  color: #735de5;
  top: 74%;
  left: 2%;
  animation-duration: 62s;
  animation-delay: -83s;
  transform-origin: 16vw -17vh;
  box-shadow: -6vmin 0 1.0878004719418783vmin currentColor;
}

.background span:nth-child(1) {
  color: #ffe1ad;
  top: 32%;
  left: 70%;
  animation-duration: 133s;
  animation-delay: -104s;
  transform-origin: 6vw -2vh;
  box-shadow: 6vmin 0 1.6577360654038036vmin currentColor;
}

/* ... continue with all the span styles from bg.css ... */
.background span:nth-child(44) {
  color: #ffe1ad;
  top: 19%;
  left: 91%;
  animation-duration: 148s;
  animation-delay: -113s;
  transform-origin: -8vw 21vh;
  box-shadow: -6vmin 0 1.7124201431758679vmin currentColor;
}

.background span:nth-child(45) {
  color: #735de5;
}

/* Add the animation keyframes */
@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

@keyframes neon-pulse {
  0% {
    box-shadow: 0 0 2px #ec4899, 0 0 4px #ec4899, 0 0 8px #ec4899;
  }
  50% {
    box-shadow: 0 0 4px #ec4899, 0 0 8px #ec4899, 0 0 16px #ec4899;
  }
  100% {
    box-shadow: 0 0 2px #ec4899, 0 0 4px #ec4899, 0 0 8px #ec4899;
  }
}
